@tailwind base;
@tailwind components;
@tailwind utilities;

@import "components/carousel.css";

@layer base {
  @font-face {
    font-family: Museo Sans Cyrl Medium;
    font-weight: 500;
    src: url('./fonts/MuseoSansCyrl_500-webfont.woff2') format('woff2'),
      url('./fonts/MuseoSansCyrl_500-webfont.woff') format('woff');
    font-display: auto;
  }
}

@layer base {
  @font-face {
    font-family: Museo Sans Cyrl Normal;
    font-weight: 300;
    src: url('./fonts/MuseoSansCyrl_300-webfont.woff2') format('woff2'),
      url('./fonts/MuseoSansCyrl_300-webfont.woff') format('woff');
    font-display: auto;
  }
}

@layer base {
  @font-face {
    font-family: Museo Sans Cyrl Bold;
    font-weight: 700;
    src: url('./fonts/MuseoSans_700.woff') format('woff');
    font-display: auto;
  }
}

@layer components {
  .has-selections {
    @apply .border .border-gray-400;
    border-top-color: rgb(var(--tertiary));
    border-top-width: 3px;
  }
}

@layer base {
  html {
    height: 100%;
  }

  body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h6,
  mark {
    font-family: "Museo Sans Cyrl Medium", "sans-serif" !important;;    
    @apply .text-primary-actual;

  }
}

b {
  font-family: "Museo Sans Cyrl Bold", "sans-serif" !important;  
}

.bold {
  font-family: "Museo Sans Cyrl Bold", "sans-serif" !important;  
}

.bold-highlight b {
  @apply .text-highlight-actual;
}

.bold-blue b {
  @apply .text-secondary-actual;
}

.carousel-root {
  display: flex;
  justify-content: center;
}

.carousel.carousel-slider {
  flex: 0 1 700px;
}

h1,
h2,
h3 
h4,
h6{
  font-family: "Museo Sans Cyrl Medium", "sans-serif" !important;; 
}

html {
  font-family: "Museo Sans Cyrl Normal", "sans-serif";
}

.border-teal{
  border-color: #00C1D4;
}

.border-darkteal{
 border-color: #00C1D4;
}

.border-yellow{
  border-color: #FFD100;
}

.teal-fontcolor{
  color: #003B49;
}

.ecp-rebrand-header{
  width: 40%;
  justify-content: center;
}

.remove-cta-padding {
  padding-left: 0;
  padding-right: 0;
  font-weight: 400;
}

a.large-icon .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
}

.custom-richtext h1 {
  font-size: 56px !important;
  font-weight: 300 !important;
  line-height: 110% !important;
  letter-spacing: -0.9px;
  font-family: "Museo Sans Cyrl Normal", "sans-serif" !important;
}

.button-text {
  font-family: "Museo Sans Cyrl Medium", "sans-serif" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.48px;
}

@media (max-width: 768px) {
  .custom-richtext h1 {
    font-size: 42px !important;
    line-height: 120%; 
  }
  .button-text {
    font-size: 16px;
  }
}

.font-light {
  font-family: "Museo Sans Cyrl Normal", "sans-serif" !important;
}

.maplibregl-popup-content {
  font-family: "Museo Sans Cyrl Normal", "sans-serif" !important;;  
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}


/* Aspect Ratios */
.aspect-ratio-portrait{
  aspect-ratio: 8/10;
}

.aspect-ratio-square{
  aspect-ratio: 1/1;
}

.aspect-ratio-landscape{
  aspect-ratio: 16/10;
}

.aspect-ratio-panorama{
  aspect-ratio: 16/8;
}