@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slick-arrow {
  width: 28px;
  height: 60px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  text-indent: -9999px;
  top: 35%;
  &.slick-prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='47' viewBox='0 0 28 47' fill='none'%3E%3Cpath d='M24 43.5L4 23.5L24 3.5' stroke='%2300C1D4' stroke-width='6.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    left: -50px;
  }
  &.slick-next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='47' viewBox='0 0 28 47' fill='none'%3E%3Cpath d='M4 43.5L24 23.5L4 3.5' stroke='%2300C1D4' stroke-width='6.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    right: -50px;
  }
  &.slick-disabled {
    opacity: 0.5;
  }
}

.slick-dots {
  display: none;
}

.carousel-reviews {
  background-color: rgb(var(--muted));
}

.carousel-reviews .slick-slide > *{
  background: #fff;
  padding: 1rem;
  font-size: 14px;
}

.carousel-reviews .slick-arrow {
    top: 50%;
}


.slick-list {
    display: flex;
}

.slick-track {
    display: flex;
    gap: 2rem;
    @media (max-width: 768px) {
        gap: 1rem;
    }
}

.slick-slide,
.slick-initialized .slick-slide {
    display: flex;
}

.slider-content {
    display: inline-flex !important;
}

.slider-content > div {
    display: flex;
    width: 100%;
}

.slick-slide > div {
    display: flex;
    width: 100%;
}